import styled from "styled-components"
import { gridLayout } from "../../styles/mixins"
import { main } from "../../styles/theme"

export const Wrapper = styled.section`
  ${gridLayout}
  padding: 50px 0;
`

export const LeftImage = styled.div`
  margin-bottom: 40px;
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    grid-column: 1 / span 6;
  }
`

export const RightImage = styled.div`
  grid-column: 1 / -1;
  @media ${main.breakpoints.medium} {
    grid-column: 7 / span 6;
  }
`
