import React from "react"
import { H3 } from "../../styles/typography"
import { TextWrapper, Wrapper, TextBlock, Dots } from "./styles"
import { arrayOf, shape, string, oneOfType, number, bool } from "prop-types"

const ApartmentInfoBlock = ({ list, text, marginBottom }) => {
  return (
    <Wrapper marginBottom={marginBottom}>
      <TextWrapper>
        <TextBlock>
          {list.map(({ key, value }) => (
            <div key={key}>
              <H3>{key}</H3>
              <Dots />
              <H3>{value}</H3>
            </div>
          ))}
        </TextBlock>
        <TextBlock>
          <H3>{text}</H3>
        </TextBlock>
      </TextWrapper>
    </Wrapper>
  )
}

ApartmentInfoBlock.propTypes = {
  list: arrayOf(shape({ key: string, value: oneOfType([string, number]) }))
    .isRequired,
  text: string.isRequired,
  marginBottom: bool,
}

export default ApartmentInfoBlock
