import React from "react"
import PropTypes from "prop-types"
import { Wrapper, LeftImage, RightImage } from "./styles"

const TwoColumnImageBlock = ({ leftImgSrc, rightImgSrc, leftImageEl, rightImageEl}) => {
  return (
    <Wrapper>
      {leftImageEl && (
        <LeftImage>
          {leftImageEl}
        </LeftImage>
      )}
      {rightImageEl && (
        <RightImage>
          {rightImageEl}
        </RightImage>
      )}
    </Wrapper>
  )
}

TwoColumnImageBlock.propTypes = {
  leftImgSrc: PropTypes.string,
  rightImgSrc: PropTypes.string,
  leftImageEl: PropTypes.element,
  rightImageEl: PropTypes.element
}

TwoColumnImageBlock.defaultProps = {
  leftImgSrc: "",
  rightImgSrc: "",
  leftImageEl: null,
  rightImageEl: null
}

export default TwoColumnImageBlock
