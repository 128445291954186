import styled from "styled-components"
import { main } from "../../styles/theme"
import { gridLayout } from "../../styles/mixins"

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;

  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? '100px' : '0'};
`
export const TextWrapper = styled.div`
  width: 100%;

  ${gridLayout}

  @media ${main.breakpoints.medium} {
    margin-top: 48px;
  }
`
export const TextBlock = styled.div`
  margin-top: 48px;
  grid-column: 1 / -1;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  @media ${main.breakpoints.medium} {
    margin-top: 0;

    &:first-child {
      grid-column: 1 / span 6;
    }

    &:last-child {
      grid-column: 7 / span 6;
    }
  }
`
export const Dots = styled.div`
  flex: 1;
  border-bottom: dotted;
  margin: 0 5px;
`
