import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import PageTitle from "../components/PageTitle/pageTitle"
import ApartmentInfoBlock from "../components/ApartmentInfoBlock/apartmentInfoBlock"
import WindowDisplay from "../components/WindowDisplay/windowDisplay"
import TwoColumnImageBlock from "../components/TwoColumnImageBlock/twoColumnImageBlock"
import { useGlobalCurrentHouse } from "../globalState"
import { getTotalRooms } from "../utils/getTotalRooms"
import { main } from "../styles/theme"
import { gridLayout } from "../styles/mixins"
import { P } from "../styles/typography"

const AdditionalInfo = styled.div`
  ${gridLayout}

  padding: 0 0 60px;
  margin-top: 50px;

  > .gatsby-image-wrapper {
    grid-column: 1 / -1;
    margin-bottom: 24px;
  }

  @media ${main.breakpoints.medium} {
    > .gatsby-image-wrapper {
      grid-column: 1 / span 6;
      margin-bottom: 0;
    }
  }

  @media ${main.breakpoints.large} {
    margin-top: 100px;
  }
`

const TextContainer = styled.div`
  grid-column: 1 / -1;

  @media ${main.breakpoints.medium} {
    grid-column: 7 / span 5;
  }
`
const ImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  background-color: ${main.colors.white};
  padding-top: 20px;

  @media ${main.breakpoints.large} {
    padding-top: 0;
  }

  > div {
    grid-column: 1 / -1;

    @media ${main.breakpoints.large} {
      &:first-of-type {
        grid-column: 1 / 1;
      }
      &:last-of-type {
        grid-column: 2 / 2;
      }
    }
  }
`

const Apartment = ({ data }) => {
  const [, setCurrentHouse] = useGlobalCurrentHouse()
  const apartment = data.allSitePage.edges[0].node.context.pageContent.node
  const house =
    data.allSitePage.edges[0].node.context.pageContent.node.apartmentType.house
      .slug.current
  const infoText = apartment.paragraph ? apartment.paragraph.split("\n") : []

  useEffect(() => {
    setCurrentHouse(house)
  }, [house, setCurrentHouse])

  return (
    <Layout backgroundColor={house === "gardshuset" ? "blue" : "yellow"}>
      <Seo title={apartment.slug.current} />
      <PageTitle
        title={apartment.title}
        number={house === "gardshuset" ? 2 : 1}
        isApartment
      />
      <ImageContainer>
        <div>
          {apartment.planArrangement && (
            <GatsbyImage
              image={apartment.planArrangement.asset.gatsbyImageData}
              alt={apartment.planArrangement.altText}
            />
          )}
        </div>
        <div>
          {apartment.planDetails && (
            <GatsbyImage
              image={apartment.planDetails.asset.gatsbyImageData}
              alt={apartment.planDetails.altText}
            />
          )}
        </div>
      </ImageContainer>
      <ApartmentInfoBlock
        list={[
          {
            key: "Lägenhetsnummer",
            value: apartment.aptNumber ? apartment.aptNumber : "",
          },
          { key: "Våning", value: apartment.floor ? apartment.floor : "" },
          {
            key: "Boarea",
            value: apartment.area ? apartment.area + " kvm" : "",
          },
          {
            key: "Rum",
            value: getTotalRooms(apartment.apartmentType.type) + " rum och kök",
          },
          {
            key: "Pris",
            value: apartment.price
              ? apartment.price
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + " kr"
              : "",
          },
          {
            key: "Avgift",
            value: apartment.hireCharge
              ? apartment.hireCharge
                  .toString()
                  .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.") + " kr/mån"
              : "",
          },
          // {
          //   key: "Status",
          //   value:
          //     apartment.status === 2
          //       ? "Såld"
          //       : apartment.status === 1
          //       ? "Bokad"
          //       : "Ledig",
          // },
        ]}
        text={apartment.preamble ?? ""}
        marginBottom={apartment.windows}
      />
      {apartment.windows && <WindowDisplay />}
      {apartment.terrace && (
        <TwoColumnImageBlock
          leftImageEl={
            <StaticImage
              src="../images/kitchen.jpg"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Kök"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
          rightImageEl={
            <StaticImage
              src="../images/gardshuset-terrass-2.jpg"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Terrassen"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
      )}
      {apartment.kitchen && (
        <div style={{ padding: "50px 0" }}>
          <StaticImage
            src="../images/kitchen.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Kök"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      )}
      {apartment.privateTerrace && (
        <div style={{ padding: "50px 0" }}>
          <StaticImage
            src="../images/porthuset-terrass-cropped.jpg"
            quality={95}
            formats={["AUTO", "WEBP"]}
            alt="Privat uteplats"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      )}
      <AdditionalInfo>
        {apartment.secondImage && (
          <GatsbyImage
            image={apartment.secondImage.asset.gatsbyImageData}
            alt={apartment.secondImage.altText ?? ""}
          />
        )}
        <TextContainer>
          {infoText.map((section, index) => (
            <P key={index} size="large">
              {section}
            </P>
          ))}
        </TextContainer>
      </AdditionalInfo>
    </Layout>
  )
}

export const query = graphql`
  query($path: String!) {
    allSitePage(
      filter: {
        path: { eq: $path }
        context: { pageType: { eq: "apartment" } }
      }
    ) {
      edges {
        node {
          path
          context {
            pageContent {
              node {
                _id
                title
                slug {
                  current
                }
                floor
                status
                area
                operationCost
                aptNumber
                price
                ceilingHeight
                hireCharge
                paragraph
                preamble
                windows
                terrace
                kitchen
                privateTerrace
                secondImage {
                  asset {
                    gatsbyImageData {
                      layout
                      width
                      height
                      backgroundColor
                      images {
                        fallback {
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                }
                planArrangement {
                  asset {
                    gatsbyImageData {
                      layout
                      width
                      height
                      backgroundColor
                      images {
                        fallback {
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                  altText
                }
                planDetails {
                  asset {
                    gatsbyImageData {
                      layout
                      width
                      height
                      backgroundColor
                      images {
                        fallback {
                          sizes
                          src
                          srcSet
                        }
                      }
                    }
                  }
                  altText
                }
                apartmentType {
                  type
                  house {
                    slug {
                      current
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
export default Apartment
